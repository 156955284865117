
import { useRouter } from "vue-router";
import { ref,reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { useStore } from "vuex";

export default {
  setup() {
    const router = useRouter();

/*    const captchaSrc = ref(process.env.VUE_APP_BASE_API + "/captcha/render?" + new Date().getTime());

    const getCaptcha = () => {
        captchaSrc.value = process.env.VUE_APP_BASE_API + "/captcha/render?" + new Date().getTime();
    };*/

    let loading = ref(false);
    
    const store = useStore();

    const login: any = ref(null);

    const loginForm = reactive({
      username: "",
      password: "",
      captcha: "",
      rememberMe: false,
    });

    const loginRules = {
      username: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur",
        },
        {
              min: 5,
              max: 12,
              message: '用户名长度不合法',
              trigger: 'blur',
        }
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        {
              min: 6,
              max: 18,
              message: '密码长度不合法',
              trigger: 'blur',
        }
      ],
      captcha: [
        {
          required: true,
          message: "请输入验证码"
        }
      ]
    };

    const loginHandler = () => {

        login.value.validate((valid:any) => {
          if(valid){
            loading.value = true;

            store.dispatch("Login",loginForm).then(() => {
              router.push({path: '/'});
            }).catch(() => {
              //getCaptcha();
              loading.value = false;
            });


          }
        });
        
      };

     return{
        loginForm,
        loginRules,
        login,
        loginHandler,
        loading,
       // captchaSrc,
      // getCaptcha,
      } 
  },

};
