
import * as echarts from 'echarts';
import { ref } from 'vue';
import { onMounted } from 'vue';
export default {
  setup() {
    onMounted(() => {
      let echart1 = echarts.init(document.getElementById("chart1"));
      const option = {
        title: {
          text: '周统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 人'
          }
        },
        series: [
          {
            name: '点击量',
            type: 'line',
            data: [10, 11, 13, 11, 12, 12, 9],
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: '平均值' }]
            }
          },
          {
            name: '用户量',
            type: 'line',
            data: [1, 0, 2, 5, 3, 2, 0],
            markPoint: {
              data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
            },
            markLine: {
              data: [
                { type: 'average', name: 'Avg' },
                [
                  {
                    symbol: 'none',
                    x: '90%',
                    yAxis: 'max'
                  },
                  {
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: '最高'
                    },
                    type: 'max',
                    name: '最高点'
                  }
                ]
              ]
            }
          }
        ]
      };
      echart1.setOption(option);

      let echart2 = echarts.init(document.getElementById("chart2"));

      const option2 = {
        title: {
          text: '五维分析'
        },
        legend: {
          data: ['用户行为', '用户行为2']
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '行为1', max: 6500 },
            { name: '行为2', max: 16000 },
            { name: '行为3', max: 30000 },
            { name: '行为4', max: 38000 },
            { name: '行为5', max: 52000 }
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000],
                name: '用户行为'
              },
              {
                value: [5000, 14000, 28000, 26000, 42000],
                name: '用户行为2'
              }
            ]
          }
        ]
      };

      echart2.setOption(option2);

      let echart3 = echarts.init(document.getElementById("chart3"));

      const option3 = {
        title: {
          text: '用户来源'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '搜索引擎' },
              { value: 735, name: '直接点击' },
              { value: 580, name: '邮箱地址' },
              { value: 484, name: '广告投放' },
              { value: 300, name: '社交媒体' }
            ]
          }
        ]
      };

      echart3.setOption(option3);

    });
    return {

    }
  }
}
