
import { defineComponent,computed,watch,onMounted,ref } from 'vue'
import { useStore } from 'vuex';
import SideBarItem from '@/layout/components/SideBarItem.vue';

export default defineComponent({
  components: {SideBarItem},
  setup() {

    const store = useStore();

    const menuRoutes = store.state.permission.menuRoutes;


    //剔除子菜单中隐藏的菜单
    const buildChildMenu = (routes: any) => {
      const routeArr = JSON.parse(JSON.stringify(routes));
      for(let i in routeArr) {
         let children = [];
         if(routeArr[i].children) {
            for (let j in routeArr[i].children) {
               if(routeArr[i].children[j].children) {
                  routeArr[i].children = buildChildMenu(routeArr[i].children);
               }
               if(routeArr[i].children[j].hidden == 0) {
                  children.push(routeArr[i].children[j]);
               }
            }
            if(children.length == 0) routeArr[i].children = null;
            else routeArr[i].children = children;
         }
      }
      return routeArr;
    }


    const sidebarMenu = buildChildMenu(menuRoutes);


    const handleMenuClick = () => {
        if(isMobile.value){
            store.state.app.sidebarClosed = true;
        }
    }

    const collapse = computed(() =>
        store.state.app.sidebarCollapse
    );
    const isMobile = computed(() =>
        store.state.app.isMobile
    );

    const sidebarClosed = computed(() => store.state.app.sidebarClosed);

    let clientWidth:any = ref(document.body.clientWidth);
    if(clientWidth.value < 650 && !isMobile.value){
        //手机
        store.dispatch('toggleMobile');
    }else if(clientWidth.value > 650 && isMobile.value){
        //电脑
        store.dispatch('toggleMobile');
    }

    watch(clientWidth,(newVal:number,oldVal:number) => {

        if(newVal < 650 && !isMobile.value){
            //手机
            store.dispatch('toggleMobile');
        }else if(newVal > 650 && isMobile.value){
            //电脑
            store.dispatch('toggleMobile');
        }
    });

    onMounted(() => {

        window.onresize = () => {
            clientWidth.value = document.body.clientWidth;
        }
    })

    return {
      collapse,
      isMobile,
      sidebarClosed,
      handleMenuClick,
      menuRoutes,
      sidebarMenu,
    }
  },
})
