import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61d36f9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-title" }
const _hoisted_2 = { class: "menu-title" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "menu-title" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "menu-title" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "menu-title" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar_item = _resolveComponent("side-bar-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return ($props.route.name && $props.route.children && !$props.route.hidden)
    ? (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: $props.route.path + $props.route.id,
        index: $props.route.path + $props.route.id
      }, {
        title: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass($props.route.icon)
          }, null, 2),
          _createElementVNode("span", _hoisted_1, _toDisplayString($props.route.name), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.route.children, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              ( item.children && !item.hidden)
                ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                    index: item.path,
                    key: index
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(item.icon)
                      }, null, 2),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (route, index) => {
                        return (_openBlock(), _createBlock(_component_side_bar_item, {
                          key: route.path + index,
                          route: route
                        }, null, 8, ["route"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                : (!item.children && !item.hidden)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      index: $setup.resolvePath(item.external, item.path),
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        (item.external === '1')
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              href: item.path,
                              target: "_blank",
                              class: "external-href"
                            }, [
                              _createElementVNode("i", {
                                class: _normalizeClass(item.icon)
                              }, null, 2),
                              _createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1)
                            ], 8, _hoisted_3))
                          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("i", {
                                class: _normalizeClass(item.icon)
                              }, null, 2),
                              _createElementVNode("span", _hoisted_6, _toDisplayString(item.name), 1)
                            ]))
                      ]),
                      _: 2
                    }, 1032, ["index"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["index"]))
    : (!$props.route.hidden)
      ? (_openBlock(), _createBlock(_component_el_menu_item, {
          index: $setup.resolvePath($props.route.external, $props.route.path),
          key: $props.route.path + $props.route.id,
          style: {"background-color":"#324157"}
        }, {
          default: _withCtx(() => [
            ($props.route.external === '1')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: $props.route.path,
                  target: "_blank",
                  class: "external-href",
                  width: "100%"
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass($props.route.icon)
                  }, null, 2),
                  _createElementVNode("span", _hoisted_8, _toDisplayString($props.route.name), 1)
                ], 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("i", {
                    class: _normalizeClass($props.route.icon)
                  }, null, 2),
                  _createElementVNode("span", _hoisted_10, _toDisplayString($props.route.name), 1)
                ]))
          ]),
          _: 1
        }, 8, ["index"]))
      : _createCommentVNode("", true)
}