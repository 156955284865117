

import * as path from "path";

export default {
  name: "SideBarItem",
  props: {
    route: {
      required: true,
    },
  },
  setup() {

    const resolvePath = (external: string,routePath: string) => {
      if(external === '1') {
        return "#" + routePath;
      } else {
        return path.resolve(routePath)
      }
    }

    return {
      resolvePath,
    }
  }
}
