
import { computed } from "vue";
import { useStore } from "vuex";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isActive = (path) => {
      return path === route.fullPath;
    };

    const store = useStore();
    const tabsList = computed(() => store.state.tabs.tabsList);
    const showTabs = computed(() => tabsList.value.length > 0);

    // 关闭单个标签
    const closeTabs = (index) => {
      const delItem = tabsList.value[index];
      store.commit("delTabsItem", { index });
      const item = tabsList.value[index]
          ? tabsList.value[index]
          : tabsList.value[index - 1];
      if (item) {
        delItem.path === route.fullPath && router.push(item.path);
      } else {
        router.push("/");
      }
    };

    // 设置标签
    const setTabs = (route) => {
      const isExist = tabsList.value.some((item) => {
        return item.path === route.fullPath;
      });
      if (!isExist) {
        if (tabsList.value.length >= 8) {
          store.commit("delTabsItem", { index: 0 });
        }
        store.commit("setTabsItem", {
          name: route.name,
          title: route.meta.title,
          path: route.fullPath,
        });
      }
    };
    setTabs(route);
    onBeforeRouteUpdate((to) => {
      setTabs(to);
    });

    // 关闭全部标签
    const closeAll = () => {
      store.commit("clearTabs");
      router.push("/");
    };
    // 关闭其他标签
    const closeOther = () => {
      const curItem = tabsList.value.filter((item) => {
        return item.path === route.fullPath;
      });
      store.commit("closeTabsOther", curItem);
    };
    const handleTabs = (command) => {
      command === "other" ? closeOther() : closeAll();
    };

    return {
      isActive,
      tabsList,
      showTabs,
      closeTabs,
      handleTabs,
    };
  },
};
